/* Outer wrapper to handle overall layout including logo space */
.terms-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  
  .logo-space {
    width: 100%;
    height: 100px; 
    background-color: #f0f0f0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .terms-container {
    max-width: 1100px;
    height: calc(100vh - 120px); 
    margin: 10px auto;
    padding: 20px;
    background: #fff;
    color: #333;
    border: 1px solid #ddd;
    box-shadow: 0 2px 8px rgba(0,0,0,0.1);
    overflow-y: scroll;
    line-height: 1.5;
  }
  
  .terms-container h1 {
    color: #0056b3;
    text-align: center;
  }
  
  .terms-container section {
    border-bottom: 10px solid #ddd;
    margin-bottom: 20px;
    padding-bottom: 10px;
  }
  
  .terms-container h2 {
    color: #0056b3;
    padding-top: 10px;
  }
  
  .terms-container p, .terms-container ul {
    font-size: 14px;
    text-align: justify;
    margin-bottom: 10px;
  }
  
  .updated-date {
    font-style: italic;
    color: #666;
    text-align: right;
  }
  
  ul {
    list-style-type: disc;
    padding-left: 20px;
  }
  
  li {
    margin-bottom: 5px;
  }
  
  a {
    color: #0066cc;
    text-decoration: none;
  }
  
  a:hover {
    text-decoration: underline;
  }
  
@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  scroll-behavior: smooth;
}

#root {
  text-align: center;
}
body{
  background-color: #FFFFFF;
}
@font-face {
  font-family: 'VisbyCF';
  src: url('./Fonts/visbycf-thin.otf') format('opentype'); /* Specify format as 'opentype' */
  font-weight: thin;
}

@font-face {
  font-family: 'VisbyCF';
  src: url('./Fonts/visbycf-regular.otf') format('opentype'); /* Specify format as 'opentype' */
  font-weight: normal;
}

@font-face {
  font-family: 'VisbyCF';
  src: url('./Fonts/visbycf-bold.otf') format('opentype'); /* Specify format as 'opentype' */
  font-weight: bold;
}
@font-face {
  font-family: 'VisbyCF';
  src: url('./Fonts/visbycf-semibold.otf') format('opentype'); /* Specify format as 'opentype' */
  font-weight: 700;
}
@font-face {
  font-family: 'VisbyCF';
  src: url('./Fonts/visbycf-medium.otf') format('opentype'); /* Specify format as 'opentype' */
  font-weight: medium;
}

.heading{
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  
}

.text-normal{
  font-family: "Poppins", sans-serif;
  font-weight: 300;
}
.text-bold{
  font-family: "Poppins", sans-serif;
  font-weight: 700;
}
.text-medium{
  font-family: "Poppins", sans-serif;
  font-weight: 500;
}

.no-wrap {
  white-space: nowrap;
}

.input-placeholder::placeholder {
  font-family: 'VisbyCF', sans-serif;
  font-style: normal;
  font-weight: thin;
  line-height: normal;
  color: #aca9a9;
}

.hero-image {
  background-image: url("./Assets/bgDiamond.svg"); /* The image used */
  background-position: right; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: contain; /* Resize the background image to cover the entire container */
}
.hero-image2 {
  background-image: url("./Assets/bgDiamond2.svg"); /* The image used */
  background-position: right; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: contain; /* Resize the background image to cover the entire container */
}
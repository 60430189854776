/* Container for the entire privacy policy */
.privacy-container {
  max-width: 1100px;
  margin: 20px auto;
  padding: 20px;
  background: #fff;
  color: #333;
  border: 1px solid #ddd;
  box-shadow: 0 2px 8px rgba(0,0,0,0.1);
  line-height: 1.5;
  overflow-y: auto; /* Allows scrolling within the container if content is too long */
  height: calc(100vh - 40px); /* Full height minus margin for a full page container */
  font-family: Arial, sans-serif; /* Ensures text is legibly styled */
}

/* Styling for main title */
.privacy-container h1 {
  color: #0056b3;
  text-align: center;
  margin-bottom: 20px; /* Adds space below the title */
}

/* Styling for section titles */
.privacy-container h2 {
  color: #0056b3;
  margin-top: 20px; /* Adds space above each section title */
  border-top: 8px solid #ddd; /* Underlines above each section title */
  padding-bottom: 30px; /* Adds padding below the title text */
}

/* Paragraph styling for better readability */
.privacy-container p, .privacy-container ul, .privacy-container li {
  font-size: 14px;
  text-align: justify;
  margin-bottom: 10px; /* Adds space below each paragraph and list */
}

/* Italic style for updated date or any other remarks */
.privacy-container .updated-date {
  font-style: italic;
  color: #666;
  text-align: right;
  margin-bottom: 20px; /* Separates the date from content below it */
}

/* List style adjustments for clarity */
.privacy-container ul {
  list-style-type: disc; /* Traditional bullet points */
  padding-left: 20px; /* Indents the list from the left edge */
}

/* Styling for list items to ensure they are spaced nicely */
.privacy-container li {
  margin-bottom: 5px;
}

/* Hyperlink styling for visibility and interactivity */
.privacy-container a {
  color: #0066cc;
  text-decoration: none; /* Removes underline by default */
}

/* Hover effect for hyperlinks */
.privacy-container a:hover {
  text-decoration: underline; /* Adds underline on hover for user feedback */
}
